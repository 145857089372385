import Vue from "vue";
import VueRouter from "vue-router";
import { routerBefore } from './permission';
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import('../views/'),
    name: "home",
    meta: {
      title: '首页'
    }
  },
  {
    path: "/vant",
    component: () => import('../views/vant.vue'),
    name: "vant",
    meta: {
      title: 'vant'
    }
  },
];

const router = new VueRouter({
  mode: "history",
  // mode: process.env.NODE_ENV == "development" ? "hash" : "history",
  base: "/home",
  routes,
});

// 路由拦截
router.beforeEach(routerBefore);

export default router;
