<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
  
export default {
  name: "App",
  data() {
    return {
   
    };
  },
  mounted() {
   
  },
  methods: {
    initPage() {
    
    },
    
  },
  mounted () {
    document.domain = "yiihuu.com"
  },
};
</script>
<style lang="scss">
img{
  display: block;
  object-fit: cover;
}
.el-loading-mask{
  position: fixed;
}
.el-loading-spinner .path{
  stroke: #FF9900;
  stroke-width:4;
}
</style>
