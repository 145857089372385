<template>
  <div class="module-header flex justify-between items-center">
    <div class="module-left flex items-center" >
      <div class="icon-wrap" >
        <slot name="iconSrc">
          <img src="../../assets/images/home_icons/item_cx.png" >
        </slot>
      </div>
      <div class="title-wrap" :style="{ color: isTitleWhite? '#FFFFFF': '#333333' }">
        <slot name="iconName">
          <span>标题名</span>
        </slot>
      </div>
    </div>
    <a class="module-right flex items-center" v-if="moreUrl" :href="moreUrl" >
      <span>更多</span>
      <van-icon name="arrow" class="arrow-icon" />
      <!-- <img src="../../assets/images/home_icons/left_arrow.png" class="arrow-icon"> -->
    </a>
  </div>
</template>

<script>
 
export default {
  props: ["isTitleWhite","iconSrc","iconName", "moreUrl", ],
  components: {},
  computed: {},
  data() {
    return {};
  },
  created() {

  },
  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
.module-header{
  padding: 0 0;
  .module-left{
    font-size: 32px;
    font-weight: 600;
    color: #333333;
  }
  .icon-wrap{
    margin-right: 14px;
    img{
      width: 50px;
      height: 50px;
    }
  }
  .module-right{
    display: flex;
    align-items: center;
    color: #AAAAAA;
    font-size: 24px;
    .arrow-icon{
      margin-top: 2px;
    }
    img{
      // width: 10px;
      height: 24px;
      margin-left: 6px;
    }
  }
}
</style>
