// 全局路由前置拦截
const routerBefore = async (to, from, next) => {
  let ua = navigator.userAgent
  let fullPath=to.fullPath
  // if(!(!!ua.match(/AppleWebKit.*Mobile.*/))){
  //   location.href = process.env.VUE_APP_MYIHU_API + '/aidraw'+ fullPath
  // }
  document.title = `${to.meta.title}_翼狐网`
  next()
}
export {
  routerBefore
}
