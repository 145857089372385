import Vue from 'vue'
import "@/global/main";
import "@/global/components";
import "@/global/common";
import "@/global/install";
import "@/assets/css/basestyle.css";
import '@/assets/css/main.css';
import '@/assets/css/elementRewrite.scss';

import App from './App.vue'
import store from '@/store'
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 3
})
import ModuleHeader from "./components/ModuleHeader"
Vue.component("ModuleHeader",ModuleHeader)
import router from './router';
new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App)
})

